import React, { createContext, useContext, useState, useEffect } from 'react';
import { addToast } from 'src/components/Notification';
import * as authService from '../providers/auth';
import { toast } from 'react-toastify';

const AuthContext = createContext({
  isAuthenticated: null,
  user: null,
  signIn: null,
  signOut: null,
  update: null,
});

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    retrieveUser();
  }, []);

  function saveUser(user) {
    setUser(user);
    localStorage.setItem('@clickrifa-app', JSON.stringify(user));
  }

  function retrieveUser() {
    const localUser = localStorage.getItem('@clickrifa-app');

    if (localUser) {
      return setUser(JSON.parse(localUser));
    }
  }

  async function signIn(payload) {
    try {
      const result = await authService.login(payload);
      if (result) {
        saveUser({ token: result.token, ...result.entidade })
      }
      return "OK";
    } catch (err) {
      if (err.message && err.message == "Unauthorized") {
      addToast(toast.TYPE.ERROR, "Login não Autorizado");
      } else {
        addToast(toast.TYPE.ERROR, err.message);
      }
      return "Erro"
    }
  }

  function signOut() {
    setUser(null);
    return localStorage.clear();
  }

  function update(payload) {
    try {
      saveUser({ token: user.token, ...payload });
      return true;
    } catch (err) {
      throw Error(err);
    }
  }
  
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        signIn,
        signOut,
        update,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export default AuthProvider;
