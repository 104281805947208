import provider from './config';

export async function login(payload) {
  try {
    const response = await provider.post('authentication/', payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }

    throw Error('Erro ao logar');
  }
}

export async function register(payload) {
  const regex = /[()._\-\s\/]/g; // regex para remover os caracteres: ()._-/ e também espaço vazio
  const params = {
    nome: payload.nome.trim(),
    ultimoNome: payload.ultimoNome.trim(),
    email: payload.email.trim(),
    telefone: payload.telefone.replace(regex, ""),
    dataNascimento: new Date(payload.dataNascimento).toISOString(),
    cpfCnpj: payload.cpfCnpj.replaceAll(".", "").replace("-", "").trim(),
    senha: payload.senha.trim(),
    aceiteTermosUso: payload.aceiteTermosUso
  }
  try {
    const response = await provider.post("/entidades/cadastro/rifeiro", params);

    return response
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }

    throw Error('Erro ao realizar o cadastro');
  }
}

export const forgotPassword = async (email) => {
  try {
    const response = await provider.post("entidades/recuperar-senha", {
      email: email.toLowerCase().trim(),
    });

    return response;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
    throw Error("Erro ao enviar endereço de email.");
  }
};

export const validateToken = async (token) => {
  try {
    const response = await provider.get(
      "entidades/recuperar-senha/validar-token",
      { params: { token: token } }
    );

    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
    throw Error("Erro ao validar token");
  }
};

export const resetPassword = async (payload) => {
  try {
    const response = await provider.post(
      "entidades/recuperar-senha/redefinir",
      {
        senha: payload.password,
        token: payload.token,
      }
    );

    return response;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
    throw Error("Erro ao redefinir senha.");
  }
};
